<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon>
        <v-img
          src="/assets/apple-touch-icon.png"
          max-height="48"
          max-width="48"
          :class="{ rotate: loading > 0 }"
        ></v-img>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Laplouf</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <Home />
    </v-main>
  </v-app>
</template>

<script>
import Home from "./components/Home";
import EventManager from "./managers/EventManager";

export default {
  name: "App",
  components: {
    Home,
  },
  data() {
    return {
      loading: 0,
    };
  },
  created() {
    EventManager.getBus().$on("loading-start-event", () => {
      this.loading++;
    });
    EventManager.getBus().$on("loading-stop-event", () => {
      this.loading--;
    });
  },
};
</script>

<style scoped>
.rotate {
  animation: rotation 1s infinite linear;
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
</style>