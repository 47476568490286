<template>
  <div>
    <v-expansion-panels accordion :value="selectedPanel">
      <v-expansion-panel disabled>
        <v-expansion-panel-header>{{
          $t("I18N_CONFIGURATION")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  ref="name"
                  v-model="name"
                  :label="$t('I18N_NAME')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  dense
                  :label="$t('I18N_THEME')"
                  :items="sprites"
                  v-model="selectedSprite"
                  outlined
                  hide-details
                >
                  <template slot="selection">
                    <v-img
                      :src="
                        'https://avatars.dicebear.com/api/' +
                        selectedSprite +
                        '/1.svg'
                      "
                      max-height="16"
                      max-width="16"
                    ></v-img>
                    <span style="width: 10px"></span>
                    <span>{{
                      $t("I18N_AVATAR_" + selectedSprite.toUpperCase())
                    }}</span>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-img
                      :src="
                        'https://avatars.dicebear.com/api/' +
                        data.item +
                        '/1.svg'
                      "
                      max-height="16"
                      max-width="16"
                    ></v-img>
                    <span style="width: 10px"></span>
                    <span>{{
                      $t("I18N_AVATAR_" + data.item.toUpperCase())
                    }}</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      :disabled="name.length == 0"
                      @click="addPerson"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $t("I18N_ADD") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      style="margin-left: 10px"
                      outlined
                      @click="addRandomPerson"
                      ><v-icon>mdi-help</v-icon></v-btn
                    >
                  </template>
                  <span>{{ $t("I18N_RANDOM") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-show="persons.length > 0" class="text-center">
              <v-col cols="12" sm="12">
                <div
                  class="person ma-3"
                  v-for="(person, index) in persons"
                  :key="person.name"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="deletePerson(index)"
                        class="delete-btn"
                        fab
                        dark
                        small
                        >X</v-btn
                      >
                      <img
                        v-bind="attrs"
                        v-on="on"
                        :src="
                          'https://avatars.dicebear.com/api/' +
                          person.sprite +
                          '/' +
                          person.seed +
                          '.svg'
                        "
                      />
                    </template>
                    <span>{{ person.name }}</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="persons.length >= 2 && selectedPerson == null && !playing"
              class="text-center"
            >
              <v-col cols="12" sm="12">
                <v-btn outlined @click="pickPerson">
                  {{ $t("I18N_PLOUF_PLOUF") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel disabled>
        <v-expansion-panel-header>{{
          $t("I18N_SELECTION")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row class="text-center" v-show="selectedPerson == null">
              <v-col cols="12" sm="12">
                <div
                  :class="{
                    'random-person-img': randomPerson == person,
                    person: true,
                    'ma-3': true,
                  }"
                  v-for="person in persons"
                  :key="person.name"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <img
                        v-bind="attrs"
                        v-on="on"
                        :src="
                          'https://avatars.dicebear.com/api/' +
                          person.sprite +
                          '/' +
                          person.seed +
                          '.svg'
                        "
                      />
                    </template>
                    <span>{{ person.name }}</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="selectedPerson != null" class="text-center">
              <v-col cols="12" sm="12">
                <img
                  class="selected-person-img"
                  :src="
                    'https://avatars.dicebear.com/api/' +
                    selectedPerson.sprite +
                    '/' +
                    selectedPerson.seed +
                    '.svg'
                  "
                />
                <h1>{{ selectedPerson.name }}</h1>
              </v-col>
            </v-row>
            <v-row v-if="selectedPerson != null" class="text-center">
              <v-col cols="12" sm="12">
                <v-btn style="margin-top: 10px" outlined @click="refresh"
                  ><v-icon>mdi-autorenew</v-icon>{{ $t("I18N_RESTART") }}</v-btn
                >
                <v-btn
                  style="margin-left: 10px; margin-top: 10px"
                  outlined
                  @click="update"
                  ><v-icon>mdi-pencil-outline</v-icon
                  >{{ $t("I18N_EDIT") }}</v-btn
                >
                <v-btn
                  style="margin-left: 10px; margin-top: 10px"
                  outlined
                  @click="restart"
                  ><v-icon>mdi-plus</v-icon>{{ $t("I18N_NEW") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container fluid v-if="persons.length > 0">
      <v-row class="text-center">
        <v-col cols="12" sm="12">
          <v-text-field
            prepend-inner-icon="mdi-link-variant"
            hide-details
            outlined
            v-model="link"
            :label="$t('I18N_SHARE_LIST')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar.visible" timeout="2000">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar.visible = false"
          >{{ $t("I18N_CLOSE") }}</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import EventManager from "./../managers/EventManager";

export default {
  name: "Home",
  data() {
    return {
      persons: [],
      firstnames: [],
      sprites: ["male", "female", "human", "bottts", "avataaars", "gridy"],
      name: "",
      selectedPanel: 0,
      selectedSprite: null,
      selectedPerson: null,
      randomPerson: null,
      link: null,
      playing: false,
      snackbar: {
        visible: false,
        text: "",
      },
    };
  },
  created() {
    this.restart();
    this._checkHashParameters();
  },
  methods: {
    restart() {
      this.selectedPanel = 0;
      this.persons = [];
      this.selectedPerson = null;
      this.randomPerson = null;
      this.selectedSprite = this.sprites[0];
      this.name = "";
    },
    update() {
      this.persons.push(this.selectedPerson);
      this.selectedPerson = null;
      this.selectedPanel = 0;
    },
    refresh() {
      this.persons.push(this.selectedPerson);
      this.selectedPerson = null;
      this.pickPerson();
    },
    addRandomPerson() {
      this._loadFirstnames().then(() => {
        this.persons.push({
          name:
            this.firstnames[this._getRandomInt(this.firstnames.length)] +
            " " +
            (this.persons.length + 1),
          sprite: this.sprites[this._getRandomInt(this.sprites.length)],
          seed: this._getRandomInt(1000000),
        });
        this._updateLink();
      });
    },
    addPerson() {
      for (const person of this.persons) {
        if (person.name == this.name) {
          this.snackbar.text =
            "'" + this.name + "' fait déjà est déjà dans la partie !";
          this.snackbar.visible = true;
          return;
        }
      }
      this.persons.push({
        name: this.name,
        sprite: this.selectedSprite,
        seed: this._getRandomInt(1000000),
      });
      this.name = "";
      this.$refs.name.focus();
      this._updateLink();
    },
    deletePerson(index) {
      this.persons.splice(index, 1);
      this._updateLink();
    },
    pickPerson() {
      this.selectedPanel = 1;
      let loop = 10;
      let previsousIndex = -1;
      this.playing = true;
      EventManager.getBus().$emit("loading-start-event");
      const interval = setInterval(() => {
        const randomIndex = this._getRandomIndex(
          previsousIndex,
          this.persons.length
        );
        previsousIndex = randomIndex;
        this.randomPerson = this.persons[randomIndex];
        loop--;
        if (loop == 0) {
          clearInterval(interval);
          this.playing = false;
          this.selectedPerson = this.persons.splice(randomIndex, 1)[0];
          this.randomPerson = null;
          EventManager.getBus().$emit("loading-stop-event");
        }
      }, 500);
    },
    _loadFirstnames() {
      return new Promise((resolve) => {
        if (this.firstnames.length > 0) {
          setTimeout(() => {
            resolve();
          }, 0);
        } else {
          axios
            .get(
              "https://opendata.paris.fr/api/records/1.0/search/?dataset=liste_des_prenoms&q=&rows=20&sort=annee%2Cnombre"
            )
            .then((response) => {
              for (const record of response.data.records) {
                this.firstnames.push(record.fields.prenoms);
              }
              resolve();
            });
        }
      });
    },
    _checkHashParameters() {
      if (location.hash.length > 0) {
        try {
          this.persons = JSON.parse(
            Buffer.from(location.hash.substring(1), "base64").toString()
          );
          this._updateLink();
        } catch (e) {
          // TODO log
        }
      }
    },
    _updateLink() {
      this.link =
        window.location.host +
        "/#" +
        Buffer.from(JSON.stringify(this.persons)).toString("base64");
    },
    _getRandomIndex(previsousIndex, max) {
      let randomIndex = this._getRandomInt(max);
      while (randomIndex == previsousIndex) {
        randomIndex = this._getRandomInt(max);
      }
      return randomIndex;
    },
    _getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
  },
};
</script>

<style scoped>
h1 {
  margin: 0px;
}
.v-btn {
  height: 40px !important;
}
.person {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.random-person-img {
  width: 128px;
  height: 128px;
}
.selected-person-img {
  width: 256px;
  height: 256px;
}
.delete-btn {
  position: absolute !important;
  width: 24px !important;
  height: 24px !important;
  margin-left: 20px !important;
  margin-top: 52px !important;
}
</style>