var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{attrs:{"accordion":"","value":_vm.selectedPanel}},[_c('v-expansion-panel',{attrs:{"disabled":""}},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t("I18N_CONFIGURATION")))]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{ref:"name",attrs:{"dense":"","hide-details":"","outlined":"","label":_vm.$t('I18N_NAME')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"dense":"","label":_vm.$t('I18N_THEME'),"items":_vm.sprites,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-img',{attrs:{"src":'https://avatars.dicebear.com/api/' +
                      data.item +
                      '/1.svg',"max-height":"16","max-width":"16"}}),_c('span',{staticStyle:{"width":"10px"}}),_c('span',[_vm._v(_vm._s(_vm.$t("I18N_AVATAR_" + data.item.toUpperCase())))])]}}]),model:{value:(_vm.selectedSprite),callback:function ($$v) {_vm.selectedSprite=$$v},expression:"selectedSprite"}},[_c('template',{slot:"selection"},[_c('v-img',{attrs:{"src":'https://avatars.dicebear.com/api/' +
                      _vm.selectedSprite +
                      '/1.svg',"max-height":"16","max-width":"16"}}),_c('span',{staticStyle:{"width":"10px"}}),_c('span',[_vm._v(_vm._s(_vm.$t("I18N_AVATAR_" + _vm.selectedSprite.toUpperCase())))])],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","disabled":_vm.name.length == 0},on:{"click":_vm.addPerson}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("I18N_ADD")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"outlined":""},on:{"click":_vm.addRandomPerson}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-help")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("I18N_RANDOM")))])])],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.persons.length > 0),expression:"persons.length > 0"}],staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},_vm._l((_vm.persons),function(person,index){return _c('div',{key:person.name,staticClass:"person ma-3"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"delete-btn",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.deletePerson(index)}}},[_vm._v("X")]),_c('img',_vm._g(_vm._b({attrs:{"src":'https://avatars.dicebear.com/api/' +
                        person.sprite +
                        '/' +
                        person.seed +
                        '.svg'}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(person.name))])])],1)}),0)],1),(_vm.persons.length >= 2 && _vm.selectedPerson == null && !_vm.playing)?_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.pickPerson}},[_vm._v(" "+_vm._s(_vm.$t("I18N_PLOUF_PLOUF")))])],1)],1):_vm._e()],1)],1)],1),_c('v-expansion-panel',{attrs:{"disabled":""}},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t("I18N_SELECTION")))]),_c('v-expansion-panel-content',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedPerson == null),expression:"selectedPerson == null"}],staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},_vm._l((_vm.persons),function(person){return _c('div',{key:person.name,class:{
                  'random-person-img': _vm.randomPerson == person,
                  person: true,
                  'ma-3': true,
                }},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({attrs:{"src":'https://avatars.dicebear.com/api/' +
                        person.sprite +
                        '/' +
                        person.seed +
                        '.svg'}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(person.name))])])],1)}),0)],1),(_vm.selectedPerson != null)?_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('img',{staticClass:"selected-person-img",attrs:{"src":'https://avatars.dicebear.com/api/' +
                  _vm.selectedPerson.sprite +
                  '/' +
                  _vm.selectedPerson.seed +
                  '.svg'}}),_c('h1',[_vm._v(_vm._s(_vm.selectedPerson.name))])])],1):_vm._e(),(_vm.selectedPerson != null)?_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-btn',{staticStyle:{"margin-top":"10px"},attrs:{"outlined":""},on:{"click":_vm.refresh}},[_c('v-icon',[_vm._v("mdi-autorenew")]),_vm._v(_vm._s(_vm.$t("I18N_RESTART")))],1),_c('v-btn',{staticStyle:{"margin-left":"10px","margin-top":"10px"},attrs:{"outlined":""},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("mdi-pencil-outline")]),_vm._v(_vm._s(_vm.$t("I18N_EDIT")))],1),_c('v-btn',{staticStyle:{"margin-left":"10px","margin-top":"10px"},attrs:{"outlined":""},on:{"click":_vm.restart}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t("I18N_NEW")))],1)],1)],1):_vm._e()],1)],1)],1)],1),(_vm.persons.length > 0)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-link-variant","hide-details":"","outlined":"","label":_vm.$t('I18N_SHARE_LIST')},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}})],1)],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"timeout":"2000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v(_vm._s(_vm.$t("I18N_CLOSE")))])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }